import * as React from 'react';
import * as Sentry from '@sentry/nextjs';
import { Geo } from '@vercel/edge';
import * as cookie from 'cookie';
import { AuthStatus } from 'constants/auth';
import { RequestStatus } from 'constants/requests';
import { ACCOUNT_TYPE_COOKIE, AccountType } from 'constants/user';
import {
  CoachStatusEnum,
  GetCurrentUserQuery,
  PlatformSubscriptionPersonasEnum,
  SubscriptionStatusesEnum,
  useGetCalendarAccountsLazyQuery,
  useGetCalendarAccountsQuery,
  useGetCurrentUserLazyQuery,
  useUpdateUserLocaleAndTimezoneMutation,
} from 'types/generated/client';
import { CalendarAccounts } from 'types/generated/server';
import { identify } from 'services/client/analytics';
import api from 'services/client/api';
import { getNavigatorLanguage } from 'utils/shared/time/getNavigatorLanguage';
import { getTimezone } from 'utils/shared/time/getTimezone';
import { useViewer } from 'hooks/useViewer';

const IP_PATH = 'v1/ip';

const getIsCoachSubscriptionActive = (user: GetCurrentUserQuery['usersByPk']) => {
  return (
    user?.activePlatformSubscriptions.some(
      (subscription) =>
        subscription.status === SubscriptionStatusesEnum.Active &&
        subscription.planPrice.persona === PlatformSubscriptionPersonasEnum.Coach,
    ) || false
  );
};

const getIsPlayerSubscriptionActive = (user: GetCurrentUserQuery['usersByPk']): boolean => {
  return (
    user?.activePlatformSubscriptions.some(
      (subscription) =>
        subscription.status === SubscriptionStatusesEnum.Active &&
        subscription.planPrice.persona === PlatformSubscriptionPersonasEnum.Player,
    ) ||
    /**
     * @note Coach with active player subscription get all player features
     */
    getIsCoachSubscriptionActive(user) ||
    false
  );
};

interface IpResponse extends Geo {
  ip: string;
}

export type CurrentUserResponse =
  | (ReturnType<typeof useGetCurrentUserLazyQuery>[1] & {
      user?: GetCurrentUserQuery['usersByPk'] | null;
    })
  | null;
interface CurrentUser {
  currentUser: CurrentUserResponse;
  ipResponse: null | IpResponse;
  ipRequestStatus: RequestStatus;
  isCoachSubscriptionActive: boolean;
  isPlayerSubscriptionActive: boolean;
  calendarAccounts: CalendarAccounts[] | undefined;
  calendarAccountsLoading: boolean;
}

const DEFAULT_USER: CurrentUser = {
  currentUser: null,
  ipResponse: null,
  ipRequestStatus: RequestStatus.Idle,
  isCoachSubscriptionActive: false,
  isPlayerSubscriptionActive: false,
  calendarAccounts: [],
  calendarAccountsLoading: false,
};

export const CurrentUserContext = React.createContext(DEFAULT_USER);

export const CurrentUserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const viewer = useViewer();
  const [ipResponse, setIpResponse] = React.useState<IpResponse | null>(null);
  const [ipRequestStatus, setIpRequestStatus] = React.useState(RequestStatus.Idle);
  const [getCurrentUserQuery, queryResult] = useGetCurrentUserLazyQuery();
  const [updateUserTimezoneMutation] = useUpdateUserLocaleAndTimezoneMutation();
  const [hasIdentifiedUser, setHasIdentifiedUser] = React.useState(false);
  const { data } = queryResult;

  const [getCurrentUserLazyQuery, { loading: calendarLoading, data: calendarData }] =
    useGetCalendarAccountsLazyQuery();

  React.useEffect(() => {
    if (data?.usersByPk?.id && !hasIdentifiedUser) {
      setHasIdentifiedUser(true);
      try {
        const user = data.usersByPk;
        const {
          followingCoachesAggregate,
          groups,
          pickleballSkillLevel,
          tennisSkillLevel,
          tennisRatingScaleId,
          ...userAttributes
        } = user;
        identify({
          ...userAttributes,
          email: user.email,
          name: user.fullName,
          userId: user.id,
          additionalUserParams: {
            ...userAttributes,
            groupCount: groups?.length || 0,
            followCoachesCount: followingCoachesAggregate?.aggregate?.count || 0,
            pickleballSkillDisplayName: pickleballSkillLevel?.displayName || undefined,
            tennisSkillDisplayName: tennisSkillLevel?.displayName || undefined,
          },
        });
        Sentry.setUser({
          email: user.email,
          name: user.fullName,
          userId: user.id,
          coachStatus: user.coachStatus,
          username: user.username || '',
          groupCount: groups?.length || 0,
          followCoachesCount: followingCoachesAggregate?.aggregate?.count || 0,
          pickleballSkillDisplayName: pickleballSkillLevel?.displayName || undefined,
          tennisSkillDisplayName: tennisSkillLevel?.displayName || undefined,
        });

        if (!user.locale || !user.timezone) {
          updateUserTimezoneMutation({
            variables: {
              id: user.id,
              locale: user.locale || getNavigatorLanguage() || undefined,
              timezone: user.timezone || getTimezone() || undefined,
            },
          }).catch((e) => Sentry.captureException(e));
        }
        /**
         * @todo check if their browser timezone is different than the one we have stored
         */
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [data, hasIdentifiedUser]);

  React.useEffect(() => {
    if (!!viewer.userId && viewer.status === AuthStatus.User) {
      // NOTE: Should we check for firebase token here?
      // There are some edge race conditions where it's fetching without a token, but it seems rare and to not effect usability.
      getCurrentUserQuery({ variables: { id: viewer.userId } });
    }
  }, [viewer.status, viewer.userId, getCurrentUserQuery]);

  React.useEffect(() => {
    if (viewer.isAnonymousSession) {
      document.cookie = `${ACCOUNT_TYPE_COOKIE}=; path=/; max-age=0; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    }

    if (data?.usersByPk?.id) {
      const coachStatusCookie = cookie.serialize(
        ACCOUNT_TYPE_COOKIE,
        data?.usersByPk?.coachStatus === CoachStatusEnum.Active
          ? AccountType.Coach
          : AccountType.Player,
        {
          maxAge: 60 * 60 * 24 * 300, // 100 week
          path: '/',
          secure: process.env.NODE_ENV === 'production', // Ensures cookie is only sent over HTTPS in production
          sameSite: 'strict', // CSRF protection
        },
      );

      // Set the cookie using document.cookie
      document.cookie = coachStatusCookie;
    }
  }, [viewer, data?.usersByPk]);

  React.useEffect(() => {
    const fetchIp = async () => {
      setIpRequestStatus(RequestStatus.InProgress);
      try {
        const data: IpResponse = await api.get(IP_PATH);
        setIpResponse({
          ...data,
          city: decodeURIComponent(data.city || ''),
          region: decodeURIComponent(data.region || ''),
        });
        setIpRequestStatus(RequestStatus.Success);
      } catch (error) {
        Sentry.captureException(error);
        setIpRequestStatus(RequestStatus.Error);
      }
    };
    fetchIp();
  }, []);

  React.useEffect(() => {
    const getCalendarAccounts = async () => {
      await getCurrentUserLazyQuery({
        variables: {
          userId: viewer.userId,
        },
      });
    };
    viewer.userId && getCalendarAccounts();
  }, [viewer.userId]);

  // NOTE: This ensures we only return the current user if the viewer exists from firebase.
  // It is possible the current user could remain in the apollo cache and won't update until the useEffect above runs.
  const currentUser = {
    ...queryResult,
    user: viewer.userId ? data?.usersByPk : null,
  };

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        isCoachSubscriptionActive: getIsCoachSubscriptionActive(currentUser.user),
        isPlayerSubscriptionActive: getIsPlayerSubscriptionActive(currentUser.user),
        ipResponse: ipResponse,
        ipRequestStatus: ipRequestStatus,
        calendarAccounts: (calendarData?.calendarAccounts as CalendarAccounts[]) || [],
        calendarAccountsLoading: calendarLoading,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
