import { useCallback, useEffect } from 'react';
import { captureException } from '@sentry/nextjs';
import { capitalize } from 'lodash';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { REVIEW_COACH_QUERY_PARAM } from 'constants/pages';
import { EMPTY_COACH_AVATAR_SRC } from 'constants/user';
import {
  CoachExternalReviewSourcesEnum,
  CoachReviewTypesEnum,
  useGetCoachReviewCheckLazyQuery,
  useInsertUserReviewMutation,
} from 'types/generated/client';
import { useViewer } from 'hooks/useViewer';
import CloseIcon from 'svg/CloseIcon';
import Button, { ButtonText } from 'components/Button';
import CustomStarRating from 'components/CustomStarRating';
import Modal, { useModal } from 'components/modals/Modal';

type SubmitReviewFormValueType = {
  rating: number;
  review: string;
};

const ModalSubmitReview: React.FC = () => {
  const { isOpen, closeModal, openModal } = useModal();
  const [insertUserReview, { loading }] = useInsertUserReviewMutation();

  const [getCoachReviewCheck, { data: CoachReviewCheckData }] = useGetCoachReviewCheckLazyQuery();

  const viewer = useViewer();

  const router = useRouter();

  const coachData = CoachReviewCheckData?.coach?.[0];

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SubmitReviewFormValueType>({
    defaultValues: {
      rating: 5,
      review: '',
    },
  });

  const resetQuery = useCallback(() => {
    router.replace(router.asPath.split('?')[0], undefined, { shallow: true });
  }, [router]);

  const closeAndResetQuery = () => {
    closeModal();
    resetQuery();
  };

  const onSubmit = async (data: SubmitReviewFormValueType) => {
    try {
      const { lessonId, videoAnalysisId, coachId } = router.query;
      if (!lessonId && !videoAnalysisId) return;
      await insertUserReview({
        variables: {
          lessonId: lessonId || undefined,
          rating: data.rating,
          review: data.review,
          coachUserId: coachId,
          reviewerUserId: viewer.userId,
          videoAnalysisId: videoAnalysisId || undefined,
          type: lessonId ? CoachReviewTypesEnum.Court : CoachReviewTypesEnum.Remote,
          externalSource: CoachExternalReviewSourcesEnum.Other,
        },
      });
      reset();
      closeAndResetQuery();
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  useEffect(() => {
    const { lessonId, videoAnalysisId, coachId, playerId } = router.query;
    const init = async () => {
      try {
        const payload = {
          coachUserId: coachId,
          playerUserId: viewer.userId,
          ...(lessonId && { lessonId }),
          ...(videoAnalysisId && { videoAnalysisId }),
        };
        const coachReviewCheckRes = await getCoachReviewCheck({
          variables: payload,
          nextFetchPolicy: 'network-only',
          fetchPolicy: 'network-only',
        });

        if (coachReviewCheckRes.data?.reviews.length === 0) {
          openModal();
          return;
        } else if (coachReviewCheckRes.data!?.reviews.length > 0) {
          const coachName = coachReviewCheckRes.data?.coach?.[0]?.fullName;
          toast.success(`You have already reviewed Coach ${coachName}`);
        }
        resetQuery();
      } catch (err) {
        captureException(err);
      }
    };
    if (
      !viewer.userId ||
      typeof playerId !== 'string' ||
      typeof router.query[REVIEW_COACH_QUERY_PARAM] !== 'string'
    ) {
      return;
    }
    if (viewer.userId !== playerId || router.query[REVIEW_COACH_QUERY_PARAM] !== 'true') {
      resetQuery();
      return;
    }

    init();
  }, [getCoachReviewCheck, viewer.userId, router.query, resetQuery]);

  const formJsx = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center px-ds-2xl">
        <img
          className="relative h-36 w-36 rounded-full object-cover object-top"
          src={coachData?.profileImageProviderUrl || EMPTY_COACH_AVATAR_SRC}
        />
        <h3 className="typography-product-heading  my-6">
          How was your lesson with {capitalize(coachData?.fullName || '')}?
        </h3>
        <Controller
          name="rating"
          control={control}
          rules={{ required: 'Rating is required' }}
          render={({ field }) => (
            <CustomStarRating
              fillColorClassName="text-color-brand-primary"
              unfillColorClassName="text-color-bg-lightmode-secondary"
              starClassName={'w-10 h-10'}
              starCount={5}
              textClassName="text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary typography-product-element-label"
              gapBetweenStars="5"
              labels={['Poor', 'Mediocre', 'Average', 'Good', 'Amazing']}
              onRatingChange={field.onChange}
              initialRating={5}
            />
          )}
        />
        {errors.rating && <p className="text-red-500">{errors.rating.message}</p>}

        <div className="mt-ds-2xl w-full">
          <label className="typography-product-subheading" htmlFor="review-field">
            Leave a public review
          </label>
          <Controller
            name="review"
            control={control}
            rules={{
              required: 'Review is required',
            }}
            render={({ field }) => (
              <textarea
                {...field}
                id="review-field"
                placeholder="Say a few words about the coach and lesson."
                className="mt-3 h-[7.5rem] w-full rounded-md border-0 bg-brand-gray-50 pl-3 pr-3 font-light text-color-text-lightmode-primary focus:outline-0 dark:text-color-text-darkmode-primary"
              />
            )}
          />
          {errors.review && <p className="text-red-500">{errors.review.message}</p>}
        </div>
      </div>
      <div className="mt-14 flex flex-row-reverse border-t px-ds-2xl py-ds-xl">
        <Button size="lg" variant="primary" className="w-fit" type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    </form>
  );

  return (
    <Modal isOpen={isOpen} handleClose={closeAndResetQuery} classNameMaxWidth="max-w-[40rem]">
      <div className="flex flex-row-reverse px-ds-2xl py-ds-xl">
        <ButtonText onClick={() => closeAndResetQuery()}>
          <CloseIcon className="h-4 w-4" />
        </ButtonText>
      </div>
      {formJsx}
    </Modal>
  );
};

export default ModalSubmitReview;
